import React, {useRef} from "react";
import AliceCarousel from 'react-alice-carousel';
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import 'react-alice-carousel/lib/alice-carousel.css';
import { PostItem, FeaturedPostsContainer, CarouselContainer } from "./styles";
import { CarouselArrow } from "../../Design/CarouselArrow";
import { Link } from "gatsby";

export const FeaturedPosts = ({
  posts
}) => {
  const carousel = useRef(null);

  const slidePrev = () => {
    carousel.current && carousel.current.slidePrev();
  };

  const slideNext = () => {
    carousel.current && carousel.current.slideNext();

  };

  const PostItems = posts?.map((post) => (
    <PostItem>
      <Link to={post.fields.slug}>
      <div className="image">
        <GatsbyImage image={getImage(post.frontmatter.image.image) || post.frontmatter.image.image} alt={post.frontmatter.image.alt} title={post.frontmatter.image.alt} />
      </div>
      <div className="text">
        <h3>
          {post.frontmatter.title}
        </h3>
        <p>
          {post.frontmatter.description}
        </p>
        <p className="date">
          Publié {post.frontmatter.date}
        </p>
      </div>
      </Link>
    </PostItem>
  ));
  return (
    <FeaturedPostsContainer>
      <CarouselArrow direction="left" onClick={slidePrev}/>
      <CarouselContainer>
        <AliceCarousel 
          ref={carousel}
          disableButtonsControls
          items={PostItems} 
          responsive={1}
          infinite
        />
      </CarouselContainer>
      <CarouselArrow direction="right" onClick={slideNext}/>
    </FeaturedPostsContainer>
  );
}

export default FeaturedPosts;