import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Core/Layout";
import { HTMLContent } from "../components/Content";
import { BlogPageComponent } from "../components/Pages/Blog";

// eslint-disable-next-line
export const BlogPageTemplate = (props) => {
  return (
    <BlogPageComponent {...props} />
  );
};

BlogPageTemplate.propTypes = {
  // TODO 
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const BlogPage = ({ data }) => {
  const { markdownRemark: content, posts } = data;
  const { frontmatter } = content;
  return (
    <Layout>
      <BlogPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        image={frontmatter.image}
        btnLink={frontmatter.btnLink}
        titleFeatured={frontmatter.titleFeatured}
        smallImage={frontmatter.smallImage}
        posts={posts.edges.map((edge) => edge.node)}
        body={content.html}
      />
    </Layout>
  );
};

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogPage;

export { Head } from "../components/Core/HtmlHead";

export const BlogPageQuery = graphql`
  query BlogPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        btnLink {
          btnLink
          btnLabel
          isAction
        }
        title
        titleFeatured
        image{
          ...imageBlock
        }
        smallImage: image {
          ...imageSmallBlock
        }
      }
    }
    posts: allMarkdownRemark (
      sort: {order: DESC, fields: [frontmatter___date]}
      filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {eq: true}}}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            title2
            podcastId
            date(formatString: "[Le] DD MMMM YYYY", locale: "fr-CA")
            featured
            image{
              ...imageBlogListBlock
            }
            smallImage: image {
              ...imageSmallBlogListBlock
            }
            description
          }
        }
      }
    }
  }
`;
