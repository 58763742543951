import * as React from "react";
import { getImage, GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import Content from "../../Content";
import { Section, ButtonLink } from "../../styles";
import FeaturedPosts from "./FeaturedPosts";
import OtherPosts from "./OtherPosts";

export const BlogPageComponent = ({
  contentComponent,
  title,
  image,
  btnLink,
  smallImage,
  titleFeatured,
  posts,
  body
}) => {
  const img = withArtDirection(getImage(image.image), [
    {
      media: "(max-width: 1024px)",
      image: getImage(smallImage.image),
    },
  ]) || image.image;
  const PageContent = contentComponent || Content;
  const featured = posts?.filter((p) => p.frontmatter.featured) || [];
  return (
    <>
      <Section variant="blogue-top">
        <div className="text">
          <h2>
            {title}
          </h2>
          <PageContent content={body} />
          {btnLink && (
            <ButtonLink to={btnLink.btnLink} isAction={btnLink.isAction}>
              {btnLink.btnLabel}
            </ButtonLink>
          )}
        </div>
        <div className="image image-top">
          <GatsbyImage image={img} alt={image.alt} title={image.alt}/>
        </div>
      </Section>
      {featured.length> 0 && (
        <Section variant="white">
          <h3 className="left">
            {titleFeatured}
          </h3>
          <FeaturedPosts posts={featured} />
        </Section>
      )}
      {posts.length > 0 && (
        <Section variant="blogue2">
          <OtherPosts posts={posts} />
        </Section>
      )}
    </>
  );
}